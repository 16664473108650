import Layout from "../../Layouts";
import { useEffect } from "react";
import SampleStruktur from "../../Assets/Image/sample-struktur.jpg";

function ProfilePage(params) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <div class="first-widget parallax" id="blog">
        <div class="parallax-overlay">
          <div class="container pageTitle">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <h2 class="page-title">Profil</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="archive-wrapper text-center">
              <h2 class="archive-title">Visi, Misi Tujuan</h2>
              <h3 class="archive-title">Visi</h3>
              <p>
                Menciptakan komunitas motor yang berkarakter, menjunjung tinggi
                nilai-nilai kekeluargaan dan kebersamaan.
              </p>
              <h3 class="archive-title">Misi</h3>
              <p>
                Menjalin kebersamaan antar komunitas motor, meningkatkan
                kualitas dan memperluas jaringan, serta peduli terhadap
                lingkungan sekitar.
              </p>
              <h3 class="archive-title">Tujuan</h3>
              <p>
                Mencapai visi dan misi melalui kegiatan-kegiatan positif yang
                menginspirasi dan memberikan manfaat bagi masyarakat.
              </p>
              <hr></hr>
              <h2 class="archive-title">Sejarah</h2>
              <p>
                Awalnya berdiri VIORI sendiri atas azas kekeluarga dan
                transparansi dalam satu kesamaan penunggang Vario. Vario
                dibilang peminat dan peredaran di kalangan matic lover terlihat
                subur dan menjamur, mulai daria emak-emak sampai kawula gen Z
                berkecimpung didalam. Adanya klub vario 160 ini akan menjadi
                wadah saling sharing tentang seputar Honda Vario 160 series
                mulai dari spare part, upgrade CVT sampai modif kirian style
                racing.<br></br>
                Kecintaan akan satu merek Honda Vario 160 dan bangga riding
                bersama merupakan label yang menyatukan. VIORI memulai tahun
                2023 setelah pandemi mendera negara ini selama 2 tahun adalah
                hal yang tepat. Karena perekonomian mulai bergerak dan
                acara-acara offline mulai diperbolehkan, tidak ada pembatasan
                yang nyata maka VIORI lahir.
              </p>
              <hr></hr>
              <h2 class="archive-title">Struktur Organisasi</h2>
              <p>
                <img src={SampleStruktur} style={{ height: "500px" }} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ProfilePage;
