import { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Layout from "../../Layouts";
import MasterContext from "../../Services";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

function RegistrationPage(params) {
  const navigate = useNavigate();
  const { loading, getAllMaster, addMasterForm } = useContext(MasterContext);
  const [dataChapter, setDataChapter] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const getDataChapter = async () => {
    const newData = await getAllMaster("public/chapter");
    if (newData) {
      setDataChapter(
        newData?.map((item) => ({ value: item.id, label: item.name }))
      );
    }
  };

  useEffect(() => {
    getDataChapter();
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const addAction = async (values) => {
    const formData = new FormData();
    // formData.append("member_number", values.member_number);
    formData.append("name", values.name);
    formData.append("nickname", values.nickname);
    formData.append("ktp_number", values.ktp_number);
    formData.append(
      "personal_whatsapp_number",
      values.personal_whatsapp_number
    );
    formData.append("emergency_contact_name", values.emergency_contact_name);
    formData.append(
      "emergency_contact_number",
      values.emergency_contact_number
    );
    formData.append("email", values.email);
    formData.append("gender", values.gender);
    formData.append("date_of_place", values.date_of_place);
    formData.append("date_of_birth", values.date_of_birth);
    formData.append("ktp_address", values.ktp_address);
    formData.append("domicile_address", values.domicile_address);
    formData.append("religion", values.religion);
    formData.append("blood_type", values.blood_type);
    formData.append("marital_status", values.marital_status);
    formData.append("sim_number", values.sim_number);
    formData.append("license_plate_number", values.license_plate_number);
    formData.append("chassis_number", values.chassis_number);
    formData.append("engine_number", values.engine_number);
    formData.append("m_chapters_id", values.m_chapters_id);
    formData.append("ktp_file", values.ktp_file ? values.ktp_file[0] : "");
    formData.append("sim_file", values.sim_file ? values.sim_file[0] : "");
    formData.append("stnk_file", values.stnk_file ? values.stnk_file[0] : "");
    formData.append("status", values.status);

    let response = await addMasterForm("public/registration", formData);
    if (!response?.message) {
      navigate("/");
      toast.success("Add Data Success");
    }
  };

  return (
    <Layout>
      <div class="first-widget parallax" id="blog">
        <div class="parallax-overlay">
          <div class="container pageTitle">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <h2 class="page-title">Registrasi</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <h3 className="fw-bold text-center mb-3">Registrasi Member VIORI</h3>
        <div className="mb-3">
          <label htmlFor="m_chapters_id" className="form-label">
            Chapter
          </label>
          <Controller
            control={control}
            name="m_chapters_id"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                value={dataChapter.find((c) => c.value === value)}
                onChange={(val) => {
                  onChange(val.value);
                }}
                options={dataChapter}
                className="form-control p-0 border-0"
              />
            )}
          />
          <small className="text-danger">{errors.m_chapters_id?.message}</small>
        </div>
        {/* <div className="mb-3">
          <label htmlFor="member_number" className="form-label">
            Nomor Member
          </label>
          <input
            type="text"
            id="member_number"
            className="form-control"
            {...register("member_number")}
            placeholder="Enter member number"
          />
          <small className="text-danger">{errors.member_number?.message}</small>
        </div> */}
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Nama Lengkap Sesuai KTP
          </label>
          <input
            type="text"
            id="name"
            className="form-control"
            {...register("name")}
            placeholder="Enter fullname"
          />
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="nickname" className="form-label">
            Nama Panggilan
          </label>
          <input
            type="text"
            id="nickname"
            className="form-control"
            {...register("nickname")}
            placeholder="Enter nickname"
          />
          <small className="text-danger">{errors.nickname?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="ktp_number" className="form-label">
            Nomor KTP
          </label>
          <input
            type="text"
            id="ktp_number"
            className="form-control"
            {...register("ktp_number")}
            placeholder="Enter KTP number"
          />
          <small className="text-danger">{errors.ktp_number?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="personal_whatsapp_number" className="form-label">
            Nomor Whatsapp Pribadi
          </label>
          <input
            type="text"
            id="personal_whatsapp_number"
            className="form-control"
            {...register("personal_whatsapp_number")}
            placeholder="Enter member personal whatsapp"
          />
          <small className="text-danger">
            {errors.personal_whatsapp_number?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="emergency_contact_name" className="form-label">
            Nama Kontak Darurat
          </label>
          <input
            type="text"
            id="emergency_contact_name"
            className="form-control"
            {...register("emergency_contact_name")}
            placeholder="Enter emergency contact name"
          />
          <small className="text-danger">
            {errors.emergency_contact_name?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="emergency_contact_number" className="form-label">
            Nomor Whatsapp Kontak Darurat
          </label>
          <input
            type="text"
            id="emergency_contact_number"
            className="form-control"
            {...register("emergency_contact_number")}
            placeholder="Enter emergency contact whatsapp"
          />
          <small className="text-danger">
            {errors.emergency_contact_number?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email Pribadi
          </label>
          <input
            type="email"
            id="email"
            className="form-control"
            {...register("email")}
            placeholder="Enter emergency contact whatsapp"
          />
          <small className="text-danger">{errors.email?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="gender" className="form-label">
            Jenis Kelamin
          </label>
          <select id="gender" className="form-select" {...register("gender")}>
            <option value="Laki-Laki">Laki-Laki</option>
            <option value="Perempuan">Perempuan</option>
          </select>
          <small className="text-danger">{errors.gender?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="date_of_place" className="form-label">
            Tempat Lahir
          </label>
          <input
            type="text"
            id="date_of_place"
            className="form-control"
            {...register("date_of_place")}
            placeholder="Enter place of birth"
          />
          <small className="text-danger">{errors.date_of_place?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="date_of_birth" className="form-label">
            Tanggal Lahir
          </label>
          <input
            type="date"
            id="date_of_birth"
            className="form-control"
            {...register("date_of_birth")}
            placeholder="Enter date of place"
          />
          <small className="text-danger">{errors.date_of_birth?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="ktp_address" className="form-label">
            Alamat Lengkap Sesuai KTP
          </label>
          <input
            type="text"
            id="ktp_address"
            className="form-control"
            {...register("ktp_address")}
            placeholder="Enter ktp address"
          />
          <small className="text-danger">{errors.ktp_address?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="domicile_address" className="form-label">
            Alamat Lengkap Domisili
          </label>
          <input
            type="text"
            id="domicile_address"
            className="form-control"
            {...register("domicile_address")}
            placeholder="Enter domicile address"
          />
          <small className="text-danger">
            {errors.domicile_address?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="religion" className="form-label">
            Agama
          </label>
          <select
            id="religion"
            className="form-select"
            {...register("religion")}
          >
            <option value="Islam">Islam</option>
            <option value="Kristen">Kristen</option>
            <option value="Katolik">Katolik</option>
            <option value="Buddha">Buddha</option>
            <option value="Konghucu">Konghucu</option>
            <option value="Hindu">Hindu</option>
          </select>
          <small className="text-danger">{errors.religion?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="blood_type" className="form-label">
            Golongan Darah
          </label>
          <select
            id="blood_type"
            className="form-select"
            {...register("blood_type")}
          >
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="AB">AB</option>
            <option value="O">O</option>
          </select>
          <small className="text-danger">{errors.blood_type?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="marital_status" className="form-label">
            Status Perkawinan
          </label>
          <select
            id="marital_status"
            className="form-select"
            {...register("marital_status")}
          >
            <option value="Belum Kawin">Belum Kawin</option>
            <option value="Kawin">Kawin</option>
            <option value="Cerai Hidup">Cerai Hidup</option>
            <option value="Cerai Mati">Cerai Mati</option>
          </select>
          <small className="text-danger">
            {errors.marital_status?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="sim_number" className="form-label">
            Nomor SIM
          </label>
          <input
            type="text"
            id="sim_number"
            className="form-control"
            {...register("sim_number")}
            placeholder="Enter sim number address"
          />
          <small className="text-danger">{errors.sim_number?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="license_plate_number" className="form-label">
            Nomor Plat Nomor
          </label>
          <input
            type="text"
            id="license_plate_number"
            className="form-control"
            {...register("license_plate_number")}
            placeholder="Enter license plate number address"
          />
          <small className="text-danger">
            {errors.license_plate_number?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="chassis_number" className="form-label">
            Nomor Rangka
          </label>
          <input
            type="text"
            id="chassis_number"
            className="form-control"
            {...register("chassis_number")}
            placeholder="Enter chassis number"
          />
          <small className="text-danger">
            {errors.chassis_number?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="engine_number" className="form-label">
            Nomor Mesin
          </label>
          <input
            type="text"
            id="engine_number"
            className="form-control"
            {...register("engine_number")}
            placeholder="Enter engine number"
          />
          <small className="text-danger">{errors.engine_number?.message}</small>
        </div>

        <div className="mb-3">
          <label htmlFor="date_of_entry" className="form-label">
            Tanggal Masuk Member
          </label>
          <input
            type="date"
            id="date_of_entry"
            className="form-control"
            {...register("date_of_entry")}
            placeholder="Enter date of entry"
          />
          <small className="text-danger">{errors.date_of_entry?.message}</small>
        </div>

        <div className="mb-3">
          <label htmlFor="ktp_file" className="form-label">
            Foto KTP
          </label>
          <input
            type="file"
            id="ktp_file"
            className="form-control"
            {...register("ktp_file")}
          />
          <small className="text-danger">{errors.ktp_file?.message}</small>
        </div>

        <div className="mb-3">
          <label htmlFor="sim_file" className="form-label">
            Foto SIM
          </label>
          <input
            type="file"
            id="sim_file"
            className="form-control"
            {...register("sim_file")}
          />
          <small className="text-danger">{errors.sim_file?.message}</small>
        </div>

        <div className="mb-3">
          <label htmlFor="stnk_file" className="form-label">
            Foto STNK
          </label>
          <input
            type="file"
            id="stnk_file"
            className="form-control"
            {...register("stnk_file")}
          />
          <small className="text-danger">{errors.stnk_file?.message}</small>
        </div>

        <div className="mb-3">
          <Button variant="primary" onClick={handleSubmit(addAction)}>
            Submit
          </Button>
        </div>
      </div>
    </Layout>
  );
}

export default RegistrationPage;
